import { useScriptTag } from '#imports'
import { onMounted, ref } from 'vue'

declare global {
  interface Window {
    zE: (
      arg1: string,
      arg2: string,
      arg3?: string | { id: number; value: string }[],
      arg4?: () => void,
    ) => void
  }
}

interface ConversationFields {
  currentLanguage: string
  BMUserId: string
  userEmail: string
  userName: string
  orderlineId: string
  customerIssue: string
}

const widgetId = 'ze-snippet'

export function usePreTradeInZendesk(widgetPreTradeInUrl: string) {
  const isLoaded = ref(false)

  const { load } = useScriptTag(widgetPreTradeInUrl, undefined, {
    immediate: false,
    attrs: { id: widgetId },
  })

  onMounted(() => {
    load().finally(() => {
      isLoaded.value = true

      window.zE('messenger', 'hide')
    })
  })

  return isLoaded
}

export function usePostSalesZendesk(
  widgetPostSalesUrl: string,
  conversationFields: ConversationFields,
) {
  const isLoaded = ref(false)

  const { load } = useScriptTag(widgetPostSalesUrl, undefined, {
    immediate: false,
    attrs: { id: widgetId },
  })

  onMounted(() => {
    load().finally(() => {
      isLoaded.value = true

      window.zE('messenger', 'hide')

      const {
        currentLanguage,
        BMUserId,
        // userEmail,
        // userName,
        orderlineId,
        customerIssue,
      } = conversationFields

      const openWidget = () => {
        window.zE('messenger', 'show')
        window.zE('messenger', 'open')
      }

      window.zE('messenger:set', 'locale', currentLanguage)
      window.zE(
        'messenger:set',
        'conversationFields',
        [
          {
            id: 15302588227740,
            value: currentLanguage === 'fr-fr' ? 'lang_fr' : 'lang_us',
          },
          { id: 15387506326812, value: BMUserId },
          // { id: 'userEmail', value: userEmail },
          // { id: 'userName', value: userName },
          { id: 15302561170076, value: orderlineId },
          { id: 15302618316700, value: customerIssue },
        ],
        openWidget,
      )
    })
  })

  return isLoaded
}
