<template>
  <div class="mx-32 my-16 flex flex-col gap-32">
    <p v-if="Object.values(selectedValues).flat().length > 0">
      Selected values: {{ selectedValues }}
    </p>
    <p v-else>Select a value</p>
    <FacetFilter
      v-for="facet in facets"
      :id="facet.name"
      :key="facet.name"
      v-model="facet.filters"
      :name="facet.name"
      :title="facet.title"
      tracking="product list > side filter"
      :type="facet.type"
      :values="facet.values"
      variant="default"
    />
  </div>
</template>

<script setup lang="ts">
import { reactive, ref } from 'vue'

import FacetFilter from '@/scopes/product-list/components/Refinements/FacetFilter.vue'

const selectedValues = ref<Record<string, string[]>>({})

const facets = reactive<
  Array<{
    title: string
    name: string
    type: 'radio' | 'checkbox'
    values: Array<{ label: string; name: string }>
    filters: string[]
  }>
>([
  {
    title: 'Foo',
    name: 'foo',
    type: 'checkbox',
    values: [
      { name: 'foo', label: 'Foo Value' },
      { name: 'bar', label: 'Bar Value' },
      { name: 'baz', label: 'Baz Value' },
    ],
    filters: ['foo', 'foobar'],
  },
  {
    title: 'Foo',
    name: 'foo2',
    type: 'checkbox',
    values: [
      { name: 'foo', label: 'Foo Value' },
      { name: 'bar', label: 'Bar Value' },
      { name: 'baz', label: 'Baz Value' },
    ],
    filters: [],
  },
  {
    title: 'Bar',
    name: 'bar',
    type: 'radio',
    values: [
      { name: 'apple', label: 'Apple fruit' },
      { name: 'banana', label: 'Banana' },
    ],
    filters: ['apple'],
  },
])
</script>
