<template>
  <div class="flex flex-col" :class="{ hidden: !isLoaded }">
    <BlockTitle class="mb-24" :subtitle :title />
    <div ref="trustpilotWidget" v-bind="configuration">
      <RevLink
        :href="configuration?.href"
        rel="noreferrer noopener nofollow"
        target="_blank"
      >
        Trustpilot
      </RevLink>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue'

import type { TrustpilotWidgetProps } from '@backmarket/http-api/src/api-specs-content/models/trustpilot-widget'
import BlockTitle from '@backmarket/nuxt-layer-cms/BlockTitle.vue'
import { RevLink } from '@ds/components/Link'

import { useTrustpilotCarousel } from '~/scopes/trustmark/Trustpilot/useTrustpilot'

defineProps<TrustpilotWidgetProps>()

const trustpilotWidget = ref<HTMLElement>()

const { configuration, isLoaded } = useTrustpilotCarousel(trustpilotWidget)
</script>
